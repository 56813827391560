































































































































































































































































































import { ElForm } from 'element-ui/types/form';
import {
  ElUploadInternalFileDetail,
  ElUploadInternalRawFile,
  HttpRequestOptions,
} from 'element-ui/types/upload';
import Vue from 'vue';
import _ from 'lodash';
import { $cookies } from '~/util/api';
import { gaodeKey } from '~/util/common';
import { ResponseCode } from '~/util/constant';
import { uploadFile } from '~/util/oss';
import MyUpload from '~/pages/manager/components/myUpload.vue';

let player: any = null;

export default Vue.extend({
  components: {
    'my-upload': MyUpload,
  },
  async asyncData(ctx) {
    const detailRes: any = await ctx.$axios.get('/api/user/myCertification');
    let detail: Record<string, any> = {};
    const idCardList: any[] = [];
    const buslicenseList: any[] = [];
    const qualificationList: any[] = [];
    const enterpricePicList: any[] = [];
    const enterpriceVideoList: any[] = [];

    if (detailRes.code === ResponseCode.SUCCESS) {
      detail = detailRes.data || {};
    }

    detail.address = detail.address?.split('/') || [];
    const serviceOrigion: any[] = [];
    if (detail.serviceOrigion?.length > 0) {
      detail.serviceOrigion.forEach((item: any) => {
        serviceOrigion.push({
          value: item,
          key: Date.now(),
        });
      });
      detail.serviceOrigion = serviceOrigion;
    } else {
      detail.serviceOrigion = [{ value: '', key: Date.now() }];
    }

    // 图片处理
    if (detail.idCard) {
      detail.idCard.forEach((img: string) => {
        idCardList.push({
          name: img,
          url: img,
        });
      });
    } else {
      detail.idCard = [];
    }

    if (detail.buslicense) {
      detail.buslicense.forEach((img: string) => {
        buslicenseList.push({
          name: img,
          url: img,
        });
      });
    } else {
      detail.buslicense = [];
    }

    if (detail.qualification) {
      detail.qualification.forEach((img: string) => {
        qualificationList.push({
          name: img,
          url: img,
        });
      });
    } else {
      detail.qualification = [];
    }

    if (detail.enterpricePic) {
      detail.enterpricePic.forEach((img: string) => {
        enterpricePicList.push({
          name: img,
          url: img,
        });
      });
    } else {
      detail.enterpricePic = [];
    }

    if (detail.enterpriceVideo) {
      detail.enterpriceVideo.forEach((img: string) => {
        enterpriceVideoList.push({
          name: img?.split('/')[4] || '视频',
          url: img,
        });
      });
    } else {
      detail.enterpriceVideo = [];
    }

    const authForm = {
      address: detail.address,
      addressDetail: detail.addressDetail,
      brif: detail.brif,
      buslicense: JSON.stringify(detail.buslicense),
      capital: detail.capital,
      companyName: detail.companyName,
      companyNameE: detail.companyNameE,
      companyPhone: detail.companyPhone,
      contactName: detail.contactName,
      contactPhone: detail.contactPhone,
      email: detail.email,
      enterpricePic: JSON.stringify(detail.enterpricePic),
      enterpriceVideo: JSON.stringify(detail.enterpriceVideo),
      idCard: JSON.stringify(detail.idCard),
      position: detail.position,
      qq: detail.qq,
      qualification: JSON.stringify(detail.qualification),
      serviceOrigion: detail.serviceOrigion,
      serviceType: detail.serviceType,
      staff: detail.staff,
      wx: detail.wx,
    };

    return {
      authForm,
      idCardList,
      buslicenseList,
      qualificationList,
      enterpricePicList,
      enterpriceVideoList,
    };
  },
  data(): any {
    const checkPhone = (
      _rule: any,
      value: string,
      callback: (msg?: string) => void
    ) => {
      const phoneReg = /^1[\d]{10}$/;
      if (!phoneReg.test(value)) {
        callback('请输入正确的手机格式');
      } else {
        callback();
      }
    };

    const checkTel = (
      _rule: any,
      value: string,
      callback: (msg?: string) => void
    ) => {
      const telReg = /^[\d]{7,16}$/;
      if (!telReg.test(value)) {
        callback('请输入正确的电话格式');
      } else {
        callback();
      }
    };

    return {
      ossDir: '',
      loading: false,
      area: [],
      detail: {},
      authForm: {
        // serviceType: 'TODO', // TODO 设计稿没有这个字段
        serviceOrigion: [{ value: '', key: Date.now() }],
        idCard: '',
        buslicense: '',
        qualification: '',
        enterpricePic: '',
        enterpriceVideo: '',
      },
      showPreviewImage: false,
      previewImage: '',
      showPreviewVideo: false,
      previewVideo: '',
      phoneNumber: '',
      rules: {
        companyName: [
          { required: true, message: '请输入您的公司名称', trigger: 'blur' },
        ],
        address: [
          { required: true, message: '请选择您公司的地址', trigger: 'blur' },
        ],
        addressDetail: [
          {
            required: true,
            message: '请输入您公司的详细地址',
            trigger: 'blur',
          },
        ],
        brif: [
          { required: true, message: '请输入您的公司介绍', trigger: 'blur' },
        ],
        companyPhone: [
          { required: true, message: '请输入您公司的电话', trigger: 'blur' },
          { validator: checkTel, trigger: 'blur' },
        ],
        capital: [
          { required: true, message: '请选择企业注册资金', trigger: 'blur' },
        ],
        staff: [{ required: true, message: '请选择员工人数', trigger: 'blur' }],
        serviceOrigion: [
          { required: true, message: '请选择服务区域', trigger: 'blur' },
        ],
        contactName: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' },
        ],
        contactPhone: [
          { required: true, message: '请输入联系人手机号', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' },
        ],
        email: [
          { required: true, message: '请输入联系人邮箱', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' },
        ],
        position: [
          { required: true, message: '请选择您的职位', trigger: 'blur' },
        ],
        idCard: [
          { required: true, message: '请上传您的身份证', trigger: 'blur' },
        ],
        buslicense: [
          {
            required: true,
            message: '请上传您公司的营业执照',
            trigger: 'blur',
          },
        ],
        qualification: [
          { required: true, message: '请上传您公司的资质', trigger: 'blur' },
        ],
      },
      areaProps: {
        label: 'name',
        value: 'name',
        children: 'districts',
      },
    };
  },
  created() {
    this.phoneNumber =
      $cookies.get('phoneNumber')?.toString() ||
      _.get(this.$store, 'state.global.user.userName', '');
    this.handleGetDistrict();
    if (this.detail.certificationStatus === 1) {
      this.authForm = this.detail;
    }
  },
  methods: {
    // 高德获取省市区三级数据
    async handleGetDistrict() {
      let areaRes: any = await this.$axios.get(
        `/ossApi/InnerChainInfo/area.json`
      );
      this.area = areaRes;
      return false
      const res: any = await this.$axios.get(
        `/v3/config/district?key=${gaodeKey}&subdistrict=3`
      );
      if (res.status === ResponseCode.SUCCESS) {
        const area = _.get(res, 'districts[0].districts', []);
        area.forEach((province: any) => {
          province.districts.forEach((city: any) => {
            city.districts.forEach((district: any) => {
              delete district.districts;
            });
          });
        });
        this.area = area;
      }
    },
    // 添加服务区域
    handleAddService() {
      this.authForm.serviceOrigion.push({
        value: '',
        key: Date.now(),
      });
    },
    // 删除服务区域
    handleRemoveService(item: any) {
      const index = this.authForm.serviceOrigion.indexOf(item);
      if (index !== -1) {
        this.authForm.serviceOrigion.splice(index, 1);
      }
    },
    // 图片上传成功
    handleSuccess(
      res: any,
      _file: ElUploadInternalFileDetail,
      _fileList: ElUploadInternalFileDetail[],
      prop: string
    ) {
      if (this.authForm[prop]) {
        try {
          const list = JSON.parse(this.authForm[prop]);
          list.push(res);
          this.authForm[prop] = JSON.stringify(list);
          this[`${prop}List`] = _fileList;
        } catch (error) {
          if (process.client && 'fundebug' in window) {
            window.fundebug.notifyError(error);
          }
          console.error('handleSuccess0 >>>', error);
        }
      } else {
        try {
          this.authForm[prop] = JSON.stringify([res]);
        } catch (error) {
          if (process.client && 'fundebug' in window) {
            window.fundebug.notifyError(error);
          }
          console.error('handleSuccess1 >>>', error);
        }
      }
    },
    // 移除图片
    handleRemove(
      file: ElUploadInternalFileDetail,
      _fileList: ElUploadInternalFileDetail[],
      prop: string
    ) {
      if (this.authForm[prop]) {
        try {
          const list = JSON.parse(this.authForm[prop]);
          const idx = list.indexOf(file.response || file.url);
          this[`${prop}List`] = _fileList;
          list.splice(idx, 1);
          this.authForm[prop] = list.length > 0 ? JSON.stringify(list) : '';
        } catch (error) {
          if (process.client && 'fundebug' in window) {
            window.fundebug.notifyError(error);
          }
          console.error('handleRemove >>>', error);
        }
      }
    },
    // 预览图片
    handlePreview(file: ElUploadInternalFileDetail) {
      this.showPreviewImage = true;
      this.previewImage = file.response || file.url;
    },
    // 上传视频前
    beforeUploadVideo(file: ElUploadInternalRawFile) {
      const isJPG = /video/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 15;

      if (!isJPG) {
        this.$message.error('上传视频只能是视频格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传视频大小不能超过 15 MB!');
      }
      return isJPG && isLt2M;
    },
    // 上传视频
    async handleUploadVideo(option: HttpRequestOptions) {
      const file = option.file;
      return await uploadFile(file);
    },
    // 视频上传成功
    handleSuccessVideo(res: string) {
      if (this.authForm.enterpriceVideo) {
        try {
          const list = JSON.parse(this.authForm.enterpriceVideo);
          list.push(res);
          this.authForm.enterpriceVideo = JSON.stringify(list);
        } catch (error) {
          if (process.client && 'fundebug' in window) {
            window.fundebug.notifyError(error);
          }
          console.error('handleSuccessVideo0 >>>', error);
        }
      } else {
        try {
          this.authForm.enterpriceVideo = JSON.stringify([res]);
        } catch (error) {
          if (process.client && 'fundebug' in window) {
            window.fundebug.notifyError(error);
          }
          console.error('handleSuccessVideo1 >>>', error);
        }
      }
    },
    // 视频移除
    handleRemoveVideo(file: ElUploadInternalFileDetail) {
      if (this.authForm.enterpriceVideo) {
        try {
          const list = JSON.parse(this.authForm.enterpriceVideo);
          const idx = list.indexOf(file.response);
          list.splice(idx, 1);
          this.authForm.enterpriceVideo =
            list.length > 0 ? JSON.stringify(list) : '';
        } catch (error) {
          if (process.client && 'fundebug' in window) {
            window.fundebug.notifyError(error);
          }
          console.error('handleRemoveVideo >>>', error);
        }
      }
    },
    // 视频预览
    handlePreviewVideo(file: ElUploadInternalFileDetail) {
      this.showPreviewVideo = true;
      this.previewVideo = file.response || file.url;
    },
    handleClosePreviewVideo() {
      if (player) {
        player.destroy(true);
      }
      this.showPreviewVideo = false;
    },
    // dialog 打开的回调
    handleVideoDialogOpened() {
      if (process.client) {
        const Player = require('xgplayer');
        require('xgplayer-mp4');
        player = new Player({
          id: 'mse',
          url: this.previewVideo,
          width: '100%',
          height: 520,
          videoInit: true,
          autoplay: false,
          preloadTime: 10,
        });
      }
    },
    // 提交认证
    handleSubmit() {
      const that = this;
      const authForm = this.$refs.authForm as ElForm;
      authForm.validate(async (valid, err) => {
        if (valid) {
          that.loading = true;
          const serviceOrigion: string[] = [];
          that.authForm.serviceOrigion.forEach((item: any) => {
            serviceOrigion.push(item.value);
          });

          let params = {};
          try {
            params = {
              ...that.authForm,
              serviceOrigion,
              address: that.authForm.address.join('/'),
              idCard: JSON.parse(that.authForm.idCard),
              buslicense: JSON.parse(that.authForm.buslicense),
              qualification: JSON.parse(that.authForm.qualification),
              enterpricePic: that.authForm.enterpricePic
                ? JSON.parse(that.authForm.enterpricePic)
                : [],
              enterpriceVideo: that.authForm.enterpriceVideo
                ? JSON.parse(that.authForm.enterpriceVideo)
                : [],
            };
          } catch (error) {
            if (process.client && 'fundebug' in window) {
              window.fundebug.notifyError(error);
            }
            console.error('handleSubmit parsms>>>', error);
          }
          const res: any = await that.$axios.post(
            '/api/user/certification',
            params
          );
          that.loading = false;
          if (res.code === ResponseCode.SUCCESS) {
            this.$message.success('申请成功.');
            this.$router.push({
              path: '/manager/',
            });
          }
        } else {
          const k = Object.keys(err)[0];
          this.$message.error(_.get(err, `${k}[0].message`, '参数错误'));
        }
      });
    },
  },
  head() {
    return {
      title: '企业认证-聚展',
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: '',
        },
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    };
  },
});
