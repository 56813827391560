










































































































































































































import Vue from 'vue';
import moment from 'moment';
import 'vue-photo-preview/dist/skin.css';
import lookIncoive from './lookIncoive.vue';
import getIncoive from './getIncoive.vue';

export default Vue.extend<any, any, any>({
  components: {
    lookIncoive,
    getIncoive,
  },
  filters: {
    stringToArray(value: string) {
      try {
        return JSON.parse(value);
      } catch (error) {
        if (process.client && 'fundebug' in window) {
          window.fundebug.notifyError(error);
        }
        return value;
      }
    },
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  } as any,
  data() {
    return {
      invoicePic: '',
      orderId: 0,
      orderType: 0,
      lookIncoiveShow: false,
      getIncoiveShow: false,
      show: false,
      maxHeight: 0,
      isTransitioning: false,
      // 1-未支付 2-已提交 3-已支付 4-已完成 9-已退款 11-免费门票
      EPayStatus: {
        1: '待支付',
        3: '出票中',
        4: '出票成功',
        9: '已退款',
        11: '出票成功',
      },
      ETicketType: {
        1: '单日票',
        2: '展期票',
        3: '联票',
      },
      timeSliceTypeOptions: {
        0: '全天',
        1: '上午',
        2: '下午',
        3: '夜间',
      },
      EOrderStatus: {
        1: '待支付',
        2: '已完成',
        3: '出门票中',
        4: '已出门票',
        9: '已退款',
        11: '已完成',
      },
      EPayType: {
        1: '支付宝',
        2: '微信',
        3: '微信',
        4: '微信',
        5: '限时免费',
        7: '百度',
      },
      picData: {},
    };
  },
  computed: {
    getStatusText() {
      const { deadlineDays: days } = this.data;
      if (days > 0) {
        return { prefix: '距离开展还有 ', value: days, suffix: ' 天' };
      } else if (days === 0) {
        return { text: '开展中' };
      } else {
        return { text: '下届时间更新中' };
      }
    },
  },
  created() {},
  methods: {
    moment,
    getPhotos(qrcode: string) {
      if (!qrcode) return [];
      return qrcode?.split(',');
    },
    toggle() {
      if (!this.isTransitioning) {
        this.show = !this.show;
      }
    },
    beforeEnter(el: any) {
      el.style.maxHeight = 0;
      this.isTransitioning = true;
    },
    enter(el: any) {
      this.maxHeight = el.scrollHeight;
    },
    afterEnter(el: any) {
      el.style.maxHeight = 'none';
      this.isTransitioning = false;
    },
    beforeLeave(el: any) {
      el.style.maxHeight = el.scrollHeight + 'px';
      this.isTransitioning = true;
    },
    leave(el: any) {
      // eslint-disable-next-line no-unused-expressions
      window.getComputedStyle(el).transform;
      el.style.maxHeight = 0;
    },
    afterLeave() {
      this.maxHeight = 0;
      this.isTransitioning = false;
    },
    toPay() {
      this.$emit('repayment', this.data);
    },
    cancelOrder() {
      this.$confirm('确定要取消订单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$emit('cancelOrder', this.data);
        })
        .catch(() => {});
    },
    handleGetList() {
      this.getIncoiveShow = false;
      this.$emit('refResh');
    },
    toInvoice(id: number, orderType: number) {
      this.orderId = id;
      this.orderType = orderType;
      this.getIncoiveShow = true;
    },
    async lookInvoice(id: string, orderType: string) {
      const { userId } = this.$store.state.global.user;
      const res: any = await this.$axios.post(
        `/api/invoiceRecordApi/getIdByOrderId`,
        {
          userId: userId,
          orderType: orderType,
          orderIds: [id],
        }
      );
      if (res.code === '1') {
        this.invoicePic = res.data.invoicePic;
        this.lookIncoiveShow = true;
      }
    },
    showTicket() {
      const { status } = this.data;
      let content = '';
      if (status === 11) {
        content =
          '您已成功订购门票，请携带观展人身份证原件刷卡入场，也可凭身份证至现场服务台换领纸质进馆证！';
      } else if (status === 3) {
        content =
          '您购买的门票正在出票中，将在展前1-2周以短信及邮件形式发送至您的手机及邮箱，请注意查收！';
      } else if (status === 9) {
        content = '您购买的门票已退款，支付金额将原路退回，请注意查收！';
      }

      this.$confirm(content, '提示', {
        confirmButtonText: '确定',
        showCancelButton: false,
      }).catch((e: any) => {
        console.log('关闭弹窗', e);
      });
    },
  },
});
