

























































import Vue from 'vue';
// @ts-ignore
import vueQr from 'vue-qr';
import { ResponseCode } from '~/util/constant';
import { sleep } from '~/util/common';

export default Vue.extend({
  name: 'Repayment',
  components: {
    vueQr,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data(): any {
    return {
      payType: 'alipay',
      showBtn: true,
      showQrcode: false,
      code: '',
      disabled: false,
      isPolling: false,
      EPayType: {
        alipay: 1,
        wxpay: 2,
      },
    };
  },
  methods: {
    togglePayment(type: string) {
      this.payType = type;
      this.isPolling = false; // 停止轮询
      if (type === 'alipay') {
        this.showBtn = true;
      }
    },
    handleClose() {
      this.$emit('close');
    },
    /**
     * 重新支付
     * @returns {any}
     */
    async toPay() {
      // 1-PC支付宝支付 2-PC微信支付
      const payload = {
        id: this.data.id,
        payType: this.EPayType[this.payType],
      };
      const res = await this.$axios.post('/api/flightOrderApi/rePay', payload);
      // console.log('116-重新付款', res);
      if (res.code === ResponseCode.SUCCESS) {
        if (this.payType === 'alipay') {
          // 支付宝支付
          const div = document.createElement('div');
          /* 下面的data.content就是后台返回接收到的数据 */
          div.innerHTML = res.data.body;
          div.id = 'alipay';
          document.body.appendChild(div);
          try {
            // @ts-ignore
            document.querySelector('#alipay').querySelector('form').submit();
          } catch (error) {
            // /airTicket/payResult/success/?orderId=${this.orderId}&orderNo=${this.orderNo}&out_trade_no=${this.orderNo}
            this.$router.push(
              `/result/error/?id=${this.data.exhibitionId}&orderNo=${this.data.orderNo}`
            );
          }
        } else {
          // 微信支付
          this.showQrcode = true;
          this.code = res.data.codeUrl;
          this.isPolling = true; // 开始轮询
          await this.getPayStatus();
          setTimeout(() => {
            this.isPolling = false; // 停止轮询
            this.showQrcode = false;
          }, 60000);
        }
      }
    },
    /**
     * 查询支付结果
     * @returns {any}
     */
    async getPayStatus() {
      if (!this.isPolling) return; // 如果停止轮询，则退出
      const payload = {
        orderNo: this.data.orderNo,
      };
      const res = await this.$axios.post(
        '/api/flightOrderApi/getByOrderNo',
        payload
      );
      // console.log('159-重新付款', res);
      if (res.code === ResponseCode.SUCCESS) {
        // `/airTicket/payResult/success/?orderId=${this.orderId}&orderNo=${this.orderNo}&out_trade_no=${this.orderNo}`
        this.$router.push(
          `/result/success/?id=${this.data.exhibitionId}&orderNo=${this.data.orderNo}`
        );
      } else if (res.code === ResponseCode.ERROR) {
        this.isPolling = false; // 停止轮询
        this.showQrcode = false;
        this.$router.push(
          `/result/error/?id=${this.data.exhibitionId}&orderNo=${this.data.orderNo}`
        );
      } else {
        await sleep(1000);
        await this.getPayStatus();
      }
    },
  },
});
