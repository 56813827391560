//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import _ from 'lodash';
import moment from 'moment';
import { ResponseCode } from '~/util/constant';
import LookInvoice from '~/pages/manager/components/lookIncoive.vue';
import GetInvoice from '~/pages/manager/components/getIncoive.vue';

export default Vue.extend({
  components: {
    GetInvoice,
    LookInvoice,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      invoicePic: '',
      getInvoiceShow: false,
      lookInvoiceShow: false,
    };
  },
  computed: {
    crtTime() {
      return moment(this.data.crtTime).format('YYYY-MM-DD');
    },
    thirdOrderInfo() {
      try {
        return JSON.parse(this.data.thirdOrderInfo);
      } catch (e) {
        return {};
      }
    },
    hotelAddress() {
      return _.get(this.thirdOrderInfo, 'hotelDetailInfo.address', '');
    },
    inTime() {
      return moment(this.data.inTime).format('YYYY-MM-DD');
    },
    outTime() {
      return moment(this.data.outTime).format('YYYY-MM-DD');
    },
    days() {
      return moment(this.data.outTime)
        .endOf('day')
        .diff(moment(this.data.inTime).startOf('day'), 'days');
    },
    occupantName() {
      const names = this.thirdOrderInfo.occupantInfoList?.map(
        (item) => item.name
      );

      return names.join(', ');
    },
    viewStatus() {
      // 1取消（创建未支付）， 0未支付，1已支付，2退款中，21发起退款,3已退款（用户退款，或者第三方创建失败）,10预定成功,11已入住
      const { status } = this.data;
      switch (status) {
        case -1:
          return '已取消';
        case 0:
          return '未支付';
        case 1:
          return '已支付';
        case 2:
          return '退款中';
        case 21:
          return '发起退款';
        case 3:
          return '已退款';
        case 10:
          return '预定成功';
        case 11:
          return '已入住';
        default:
          return '';
      }
    },
  },
  methods: {
    toHotelDetail() {
      window.open(`${this.$config.HOTEL}/detail/${this.data.hotelCode}.html`, '_self');
    },
    async fetchRefund() {
      const res = await this.$axios.post(
        '/api/hotelOrderApi/refundHotelOrder',
        { id: this.data.id }
      );
      if (res.code === ResponseCode.SUCCESS) {
        this.$confirm(res.message || '已收到您的退款申请', {
          type: 'success',
          showCancelButton: false,
          showClose: false,
        }).then(() => {
          this.$emit('update');
        });
      } else {
        this.$confirm(res.errorMessage || '退款失败', {
          type: 'error',
          showCancelButton: false,
          showClose: false,
        }).then(() => {
          this.$emit('update');
        });
      }
    },
    handleRefund() {
      const h = this.$createElement;
      const confirmationVNode = h('p', null, [
        h('p', null, '亲爱的客户:'),
        h('br'),
        h(
          'p',
          { style: 'text-indent: 2em' },
          '感谢您选择我们的酒店服务！我们注意到您提交了退款申请，请您再次确认您的决定。'
        ),
        h('br'),
        h(
          'p',
          { style: 'text-indent: 2em' },
          '您是否确定要申请退款？退款成功后，您将无法享受原订单的服务，同时订单将被取消。'
        ),
        h('br'),
        h('p', '感谢您的配合与理解。'),
      ]);

      this.$confirm(confirmationVNode, '确认退款申请', {
        confirmButtonText: '取消退款',
        cancelButtonText: '确认退款',
      })
        .then(() => {
          // 取消退款
        })
        .catch(() => {
          this.fetchRefund();
        });
    },
    showInvoice() {
      this.getInvoiceShow = true;
    },
    async showLookInvoice() {
      const { userId } = this.$store.state.global.user;
      const res = await this.$axios.post(
        `/api/invoiceRecordApi/getIdByOrderId`,
        {
          userId,
          orderType: 3,
          orderIds: [this.data.id],
        }
      );
      if (res.code === '1') {
        this.invoicePic = res.data.invoicePic;
        this.lookInvoiceShow = true;
      }
    },
    handleGetList() {
      this.getInvoiceShow = false;
      this.$emit('update');
    },
  },
});
