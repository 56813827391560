






































































































import Vue from 'vue';
import moment from 'moment';
import 'vue-photo-preview/dist/skin.css';

export default Vue.extend<any, any, any>({
  filters: {
    // 价格"分"转为"元"
    handlePrice(e: string | number) {
      return Math.divide(Number(e), 100).toFixed(0);
    },
    stringToArray(value: string) {
      try {
        return JSON.parse(value);
      } catch (error) {
        if (process.client && 'fundebug' in window) {
          window.fundebug.notifyError(error);
        }
        return value;
      }
    },
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  } as any,
  data() {
    return {
      show: false,
      maxHeight: 0,
      isTransitioning: false,
      // 1-未支付 2-已提交 3-已支付 4-已完成 9-已退款 11-免费门票
      EPayStatus: {
        1: '待支付',
        3: '出票中',
        4: '出票成功',
        9: '已退款',
        11: '出票成功',
      },
      VisaType: {
        1: '旅游签证',
        2: '商务签证',
      },
      EOrderStatus: {
        '-1': '已关闭',
        0: '待支付',
        1: '已支付',
        2: '退款中',
        3: '已退款',
        30: '部分退款',
      } as any,
      EPayType: {
        1: '支付宝',
        2: '微信',
        3: '微信',
        4: '微信',
        5: '限时免费',
        7: '百度',
      } as any,
    };
  },
  computed: {
    getStatusText() {
      const { deadlineDays: days } = this.data;
      if (days > 0) {
        return { prefix: '距离开展还有 ', value: days, suffix: ' 天' };
      } else if (days === 0) {
        return { text: '开展中' };
      } else {
        return { text: '下届时间更新中' };
      }
    },
  },
  methods: {
    moment,
    getPhotos(qrcode: string) {
      if (!qrcode) return [];
      return qrcode?.split(',');
    },
    handleShowRepayment(item: any) {
      this.$emit('on-pay', item);
    },
    handleRefund(item: any) {
      this.$emit('on-refund', item.id);
    },
    toggle() {
      if (!this.isTransitioning) {
        this.show = !this.show;
      }
    },
    beforeEnter(el: any) {
      el.style.maxHeight = 0;
      this.isTransitioning = true;
    },
    enter(el: any) {
      this.maxHeight = el.scrollHeight;
    },
    afterEnter(el: any) {
      el.style.maxHeight = 'none';
      this.isTransitioning = false;
    },
    beforeLeave(el: any) {
      el.style.maxHeight = el.scrollHeight + 'px';
      this.isTransitioning = true;
    },
    leave(el: any) {
      // eslint-disable-next-line no-unused-expressions
      window.getComputedStyle(el).transform;
      el.style.maxHeight = 0;
    },
    afterLeave() {
      this.maxHeight = 0;
      this.isTransitioning = false;
    },

    showTicket() {
      const { status } = this.data;
      let content = '';
      if (status === 11) {
        content =
          '您已成功订购门票，请携带观展人身份证原件刷卡入场，也可凭身份证至现场服务台换领纸质进馆证！';
      } else if (status === 3) {
        content =
          '您购买的门票正在出票中，将在展前1-2周以短信及邮件形式发送至您的手机及邮箱，请注意查收！';
      } else if (status === 9) {
        content = '您购买的门票已退款，支付金额将原路退回，请注意查收！';
      }

      this.$confirm(content, '提示', {
        confirmButtonText: '确定',
        showCancelButton: false,
      }).catch((e: any) => {
        console.log('关闭弹窗', e);
      });
    },
  },
});
