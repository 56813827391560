























































































































































import Vue from 'vue';
import AirTicketItem from './components/AirTicketItem.vue';
import { ResponseCode } from '~/util/constant';
import { message } from '~/util/common';
// import Repayment from './components/AirTicketRepayment.vue';

export default Vue.extend({
  components: {
    AirTicketItem,
    // Repayment,
  },
  filters: {
    // 价格"分"转为"元"
    handlePrice(e: string | number) {
      return Math.divide(Number(e), 100).toFixed(0);
    },
    stringToArray(value: string) {
      try {
        return JSON.parse(value);
      } catch (error) {
        if (process.client && 'fundebug' in window) {
          window.fundebug.notifyError(error);
        }
        return value;
      }
    },
  },
  async asyncData(ctx) {},
  data() {
    return {
      activeType: '0',
      pageNum: 1,
      pageSize: 10,
      ticket: {
        total: 0,
        records: [],
      },

      EPayStatus: {
        0: '待支付',
        1: '已支付',
        2: '退款中',
        3: '已退款',
      } as any,
      ETabStatus: {
        '0': null,
        '1': 0,
        '2': 1,
        '3': 2,
        '4': 3,
        '5': -1,
      } as any,
      currentTicket: {},
      repaymentVisible: false,
      show: false,
      maxHeight: 0,
      isTransitioning: false,
      dialogFormVisible: false,
      orderId: null,
      // 阿里商旅文档: https://open.alibtrip.com/#/document/server/flightrefundprecal-ticket-refund-pre-calculation?handbookId=development-support
      refundForm: {
        flightChange: '', // 是否发生航变
        itemUnitId: '', // 用于退票的加密参数
        multiRefundCalList: [
          {
            canApplyRefund: true, // 是否能退票申请
            name: '', // 名字
            preRefundMoney: 0, // 预计退还金额(单位:分)
            refundFee: '', // 退票手续费(单位:分)
            userId: '', // 外部分销商的用户Id
          },
        ], // 多人退票预计算list
        returnReason: [
          {
            extendDesc: '', // 退票原因注释
            person: null, // 个人原因或航司原因 0-航司、1-个人
            reasonCode: '', // 原因code
            reasonShow: '', // 原因的文案展示
            reasonType: null, // 原因的类型 枚举值：
            // 0：自愿申请。
            // 1：非自愿申请(不可抗力)。
            // 2：我要改变行程计划、我不想飞。
            // 3：自愿申请（填错名字、选错日期、选错航班）。
            // 4：自愿退票（生病了无法乘机（无二甲医院证明））。
            // 5：航班延误或取消、航班时刻变更等航司原因，或疫情原因。
            // 6：身体原因且有二级甲等医院<含>以上的医院证明。
            // 7：旅客拒签或其他不可抗力因素。
            // 100：非自愿确定性应急引导。
            // 101：非自愿非确定性应急。
            // 200：特殊提退。
            volunteer: null, // 自愿或非自愿 0-非自愿、1-自愿
          },
        ], // 退票原因
        preRefundMoney: null, // 预计退还金额
        refundFee: null, // 退票手续费
        sessionId: '', // 会话ID（取自改签航班询价返回的值的isessionId）
        tips: null, // 该字段暂无
      } as any,
      loading: false,
      formLabelWidth: '120px',
      form: {
        id: '',
        flightRefundApplyRequest: {
          displayRefundMoney: '', // 给用户展示的退款金额
          isVoluntary: 0, // 是否自愿 0：非自愿, 1：自愿
          itemUnitIds: '', // 分销预计算返回的下单加密参数
          reasonDetail: '', // 退票原因
          reasonType: null, // 退票原因类型
          sessionId: '', // 分销预计算返回的sessionId
        },
      },
      confirmLoading: false,
    };
  },
  created() {
    this.handleGetList();
  },
  methods: {
    handleTabClick(tab: any) {
      this.activeType = tab.name;
      this.pageNum = 1;
      this.handleGetList();
    },
    // 获取订单列表
    async handleGetList() {
      const { pageNum, pageSize } = this;
      const ticketRes: any = await this.$axios.post(
        '/api/flightOrderApi/myOrder',
        {
          status: this.ETabStatus[this.activeType],
          pageNum,
          pageSize,
        }
      );

      if (ticketRes.code === ResponseCode.SUCCESS) {
        ticketRes.data.records.map((item: any) => {
          // item['visaMainPic'] = item.visaPic ? item.visaPic.split(',')[0] : '';
          item.flightTraveler = JSON.parse(item.flightTraveler);
        });
        this.ticket = ticketRes.data;
      }
    },

    // 换页
    handleChangePage(page: number) {
      this.pageNum = page;
      this.handleGetList();
    },
    handleShowRepayment(item: any) {
      // this.currentTicket = item
      Object.assign(this.currentTicket, { ...item });
      this.repaymentVisible = true;
    },
    async handleRefund(id: any) {
      this.orderId = id;
      this.form.id = id;
      this.dialogFormVisible = true;
      // return false;
      // if (this.orderId && this.orderId == id) return false;
      this.loading = true;
      const params = {
        id,
      };
      const result: any = await this.$axios.post(
        '/api/flightOrderApi/preRefund',
        params
      );
      this.loading = false;
      if (result.code === '1') {
        const obj = JSON.parse(JSON.stringify(result.data));
        for (const i in this.refundForm) {
          this.refundForm[i] = obj[i];
        }
        this.form.flightRefundApplyRequest.displayRefundMoney =
          obj.preRefundMoney;
        // this.form.flightRefundApplyRequest.isVoluntary = obj.
        this.form.flightRefundApplyRequest.itemUnitIds = obj.itemUnitId;
        // this.form.flightRefundApplyRequest.reasonDetail = obj.
        // this.form.flightRefundApplyRequest.reasonType = obj.
        this.form.flightRefundApplyRequest.sessionId = obj.sessionId;
      }
    },
    async confirmRefund() {
      this.confirmLoading = true;
      const params = JSON.parse(JSON.stringify(this.form));
      const result: any = await this.$axios.post(
        '/api/flightOrderApi/refund',
        params
      );
      this.confirmLoading = false;
      if (result.code == 1) {
        message.call(this, {
          delay: 3000,
          title: '退款申请已提交',
          type: 'success',
        });
        this.dialogFormVisible = false;
        this.handleGetList();
      }
    },
    reasonTypeChange(e: any) {
      const result = this.refundForm.returnReason.find(
        (item: any) => item.reasonType == e
      );
      this.form.flightRefundApplyRequest.isVoluntary = 1; // 0非自愿, 1自愿;
      this.form.flightRefundApplyRequest.reasonDetail = result.extendDesc;
      this.form.flightRefundApplyRequest.reasonType = result.reasonType;
    },
  },
  head() {
    return {
      title: '我的机票-聚展',
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: '',
        },
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    };
  },
});
