























import Vue from 'vue';

export default Vue.extend({
  name: 'lookIncoive',
  data(): any {
    return {
      dialogVisible: true,
      picData:{},
    };
  },
  props: {
    invoicePic: {
      type: String,
      default: 0,
    },
   
  },
  created(){
    
  },
  mounted() {

  },
  methods: {
    add(){
      this.$emit('confirm');
    },
    downloadImage(url :string, filename :any) {
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
});
