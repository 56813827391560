import { render, staticRenderFns } from "./getIncoive.vue?vue&type=template&id=bc5e2d38&scoped=true&"
import script from "./getIncoive.vue?vue&type=script&lang=ts&"
export * from "./getIncoive.vue?vue&type=script&lang=ts&"
import style0 from "./getIncoive.vue?vue&type=style&index=0&id=bc5e2d38&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc5e2d38",
  null
  
)

export default component.exports