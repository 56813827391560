































































































import Vue from 'vue';
import { ElForm } from 'element-ui/types/form';
import { checkEmail } from '~/util/common';

export default Vue.extend({
  name: 'lookIncoive',
  data(): any {
    return {
      dialogVisible: true,
      picData: {},
      isLoading: false,
      invoiceForm: {
        type: 0,
        invoiceETitle: '',
        invoiceETaxpayer: '',
        email: '',
        backNumber: '',
        bankName: '',
        companyAddress: '',
        companyTel: '',
      },
      rules: {
        invoiceETitle: [
          { required: true, message: '请输入发票抬头', trigger: 'blur' },
        ],
        invoiceETaxpayer: [
          { required: true, message: '请输入公司税号', trigger: 'blur' },
        ],
        email: [
          { required: true, message: '邮箱不能为空', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' },
        ],
      },
    };
  },
  props: {
    orderId: {
      type: Number,
      default: 0,
    },
    orderType: {
      // 订单类型 0-门票，1-期刊，2-签证，3-酒店，4-机票
      type: Number,
      default: 0,
    },
  },
  created() {
    this.getInvoiceInfo();
  },
  mounted() {
    // const form = this.$refs.orderform as ElForm;
    //     form.resetFields();
  },
  methods: {
    async getInvoiceInfo() {
      const res = await this.$axios.post(`/api/invoiceInfoApi/myInvoiceInfo`);
      if (res.code === '1') {
        this.invoiceForm = res.data[0] || {
          type: 0,
          invoiceETitle: '',
          invoiceETaxpayer: '',
          email: '',
          backNumber: '',
          bankName: '',
          companyAddress: '',
          companyTel: '',
        };
      } else {
        this.$message.error('操作失败');
      }
    },
    async addinvoice() {
      this.$refs.orderform.validate((valid: ElForm) => {
        if (valid) {
          this.getInvoiceRecordApi();
        }
      });

      // /invoiceRecordApi/add
    },
    async getInvoiceRecordApi() {
      const orderIds = [this.orderId];
      const orderType = this.orderType;
      const invoice = JSON.parse(JSON.stringify(this.invoiceForm));
      const params = {
        orderIds,
        orderType,
        invoice,
      };
      const res = await this.$axios.post(`/api/invoiceRecordApi/add`, params);
      if (res.code === '1') {
        const $orderForm = this.$refs.orderform as ElForm;
        $orderForm.resetFields();
        this.$emit('confirm');
        this.$message.success('开票成功');
      } else {
        this.$message.error('操作失败');
      }
    },
    close() {
      const $orderForm = this.$refs.orderform as ElForm;
      $orderForm.resetFields();
      this.$emit('confirm');
    },
  },
});
