


















import {
  ElUploadInternalFileDetail,
  ElUploadInternalRawFile,
  HttpRequestOptions,
} from 'element-ui/types/upload';
import Vue from 'vue';
import { uploadFile } from '~/util/oss';

export default Vue.extend({
  props: {
    listType: {
      type: String,
      default: 'picture-card',
    },
    accept: {
      type: String,
      default: 'image/*',
    },
    limit: Number,
    fileList: {
      type: Array,
    },
    prop: {
      type: String,
      default: '',
    },
  },
  data(): any {
    return {
      $fileList: [],
    };
  },
  methods: {
    // 图片类型上传前
    beforeImgUpload(file: ElUploadInternalRawFile) {
      const isJPG = /image/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传图片只能是图片格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // 上传
    async handleUpload(option: HttpRequestOptions) {
      const file = option.file;
      return await uploadFile(file);
    },
    handleRemove(
      file: ElUploadInternalFileDetail,
      fileList: ElUploadInternalFileDetail[]
    ) {
      this.$emit('remove', file, fileList, this.prop);
    },
    handleSuccess(
      res: any,
      file: ElUploadInternalFileDetail,
      fileList: ElUploadInternalFileDetail[]
    ) {
      this.$fileList = fileList;

      this.$emit('success', res, file, fileList, this.prop);
    },
    handlePreview(file: ElUploadInternalFileDetail) {
      this.$emit('preview', file, this.prop);
    },
  },
});
